// ul {
//     li {
//         width: 100%
//     }
//     li:nth-child(n-7) {
//         float:left;
//         text-align: left;
//     }
//     li:nth-child(n+7) {
//         float:right;
//         text-align: right;
//     }
// }

@font-face {
  font-family: "HelveticaNeueLTStd";
  src: url("../assets/fonts/HelveticaNeueLTStd-Lt.otf");
}

@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: url("../assets/fonts/SpaceGrotesk-Bold.otf");
}

@font-face {
  font-family: "UniversLTStd-Bold";
  src: url("../assets/fonts/UniversLTStd-Bold.otf");
}

.progress-slider {
  padding: 10px;
  cursor: pointer;
  width:75%;
  height: 15px;
  margin: 15px 0;
}

.progress-bar {
  height: 2px;
  background: rgba(255,255,255,.3);
}

.progress-slider:hover .progress-bar {
  height: 3px;
}

.progress-fill {
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  transform-origin: 0 50%;
  transform: scaleX(0);
}

#root {
  > div {
    height: 100%
  }
}

body, html, #root {
  height: 100%;
}

@media only screen and (min-width: 800px)  {
  body, html, #root {
    overflow: hidden;
    height: 100%;
  }
  ul {
    // height: 500px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    li {
      display: inline-block;
      width:400px;
    }
  }
}